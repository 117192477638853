export default async (string) => {
  const base64Response = await fetch(string)

  const blob = await base64Response.blob()
  const url = window.URL.createObjectURL(blob, { oneTimeOnly: true })
  const anchor = document.createElement('a')

  anchor.href = url
  anchor.target = '_blank'
  anchor.click()
}
