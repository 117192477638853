import axiosCustomer from '@/plugins/axios-customer'

export default {
  async reports(data) {
    const params = new URLSearchParams(data).toString()
    return await axiosCustomer.get(`customers-dashboard/reports?${params}`)
  },

  async complementaryReport(data) {
    const params = new URLSearchParams(data).toString()
    return await axiosCustomer.get(`complementary-report?${params}`)
  },

  async tasks(data) {
    const params = new URLSearchParams(data).toString()
    return await axiosCustomer.get(`customers-dashboard/tasks?${params}`)
  },
}
