<template>
  <div>
    <validation-observer ref="observer">
      <v-row class="mt-2">
        <v-col>
          <validation-provider v-slot="{ errors }" vid="startDate">
            <input-date
              v-model="filters.startDate"
              label="Data inicial"
              :error-messages="errors"
              solo
              dense
              flat
              clearable
            />
          </validation-provider>
        </v-col>
        <v-col>
          <validation-provider v-slot="{ errors }" vid="endDate">
            <input-date
              v-model="filters.endDate"
              label="Data final"
              :error-messages="errors"
              solo
              dense
              flat
              clearable
            />
          </validation-provider>
        </v-col>
      </v-row>
    </validation-observer>

    <v-row>
      <v-col>
        <v-btn
          color="success"
          small
          elevation="0"
          @click="downloadComplementaryReport"
          :loading="loading"
        >
          Gerar
          <v-icon right v-text="'mdi-download'" />
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import customersDashboardApi from '@/api/customers-dashboard'
import openBase64InNewTab from '@/utils/openBase64InNewTab'

export default {
  components: {
    InputDate: () => import('@/components/input/InputDate'),
  },

  data: () => ({
    loading: false,
    filters: {},
  }),

  methods: {
    async downloadComplementaryReport() {
      try {
        this.loading = true

        const response = await customersDashboardApi.complementaryReport(
          this.filters,
        )

        const dataString = `data:application/pdf;base64,${response.data.pdf}`
        await openBase64InNewTab(dataString)
      } catch (e) {
        if (e?.response?.status === 422) {
          this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
          return
        }

        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
